/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { uniq } from 'lodash'
import { Button, Checkbox, Icon, Modal, Select } from 'antd'
import { useEffect, useState } from 'react'

import Image from '../Image'

import RaidService from '../../services/RaidService'

const AddEncounterModal = (props) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [difficulty, setDifficulty] = useState(props.raid.difficulty)
  const [enabledEncounters, setEnabledEncounters] = useState(
    props.raid.extra_encounters || [],
  )
  const [addBeforeEncounter, setAddBeforeEncounter] = useState(null)
  const difficulties = ['Mythic', 'Heroic', 'Normal', 'LFR']
  const difficultiesForId = ['mythic', 'heroic', 'normal', 'raid_finder']

  const addEncounters = () => {
    setLoading(true)
    const addBeforeIndex = props.encounterOrder.indexOf(
      addBeforeEncounter?.order_id,
    )
    const newEncounters = enabledEncounters.filter(
      (e) => !(props.raid.extra_encounters || []).includes(e),
    )

    let newOrder = [...props.encounterOrder]
    addBeforeEncounter
      ? newOrder.splice(addBeforeIndex, 0, ...newEncounters)
      : (newOrder = newOrder.concat(newEncounters))

    RaidService.updateRaid(
      props.guild.id,
      props.guild.selectedTeam.id,
      props.raid.id,
      {
        encounter_order: newOrder,
        extra_encounters: enabledEncounters,
      },
    )
      .then((result) => {
        props.refreshRaid(result)
        setLoading(false)
        setOpen(false)
      })
      .catch((result) => {
        if (result.response.status == 401) window.location.reload()
        message.error(
          result.response.data?.message ||
            'Something went wrong trying to add encounters. Please try again.',
          3,
        )
        setLoading(false)
      })
  }

  const toggleInstance = (instance) => {
    if (
      props.raid.instance.id === instance.id &&
      props.raid.difficulty === difficulty
    )
      return

    const instanceEncounters = instance.encounters.map(
      (encounter) => `${encounter.id}|${difficultiesForId[difficulty]}`,
    )
    if (
      enabledEncounters.filter((e) => instanceEncounters.includes(e)).length ===
      instanceEncounters.length
    ) {
      setEnabledEncounters(
        enabledEncounters.filter((e) => !instanceEncounters.includes(e)),
      )
    } else {
      setEnabledEncounters(uniq([...enabledEncounters, ...instanceEncounters]))
    }
  }

  useEffect(() => {
    if (open) {
      setDifficulty(props.raid.difficulty)
      setAddBeforeEncounter(null)
      setEnabledEncounters(props.raid.extra_encounters || [])
    }
  }, [open])

  return (
    <div>
      <Button
        type='ghost'
        size='small'
        className='show-encounter-modal'
        onClick={() => setOpen(true)}
      >
        <Icon type='plus' />
        <span>Add encounters</span>
      </Button>
      <Modal
        title='Add encounters'
        visible={open}
        onOk={addEncounters}
        width='100%'
        style={{ maxWidth: 'min(80vw, 75rem)' }}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key='back' disabled={loading} onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={loading}
            loading={loading}
            onClick={addEncounters}
          >
            Save
          </Button>,
        ]}
      >
        <div css={ModalContentStyles}>
          <div className='intro'>
            If you would like to plan encounters from different instances and
            difficulties in the same event, you can add them here.
          </div>

          <div className='field-group'>
            {props.guild.kind === 'live' ||
            props.guild.kind === 'classic_progression' ? (
              <div className='field'>
                <label>Difficulty</label>
                <Select
                  className='difficulty'
                  placeholder='Difficulty'
                  value={difficulty}
                  optionFilterProp='children'
                  onChange={(value) => setDifficulty(value)}
                >
                  {difficulties.map((difficulty, index) => (
                    <Select.Option key={`diff-${index}`} value={index}>
                      {difficulty}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ) : (
              ''
            )}

            <div className='field'>
              <label>Add before encounter</label>
              <Select
                className='add-before'
                value={
                  props.raid.instance.encounters.find(
                    (e) => e.order_id === addBeforeEncounter?.order_id,
                  )?.order_id || null
                }
                optionFilterProp='children'
                onChange={(value) =>
                  setAddBeforeEncounter(
                    props.raid.instance.encounters.find(
                      (e) => e.order_id === value,
                    ),
                  )
                }
              >
                {props.raid.instance.encounters.map((encounter) => (
                  <Select.Option
                    key={`encounter-${encounter.order_id}`}
                    value={encounter.order_id}
                  >
                    {encounter.name}
                  </Select.Option>
                ))}
                <Select.Option value={null}>
                  <em>At the end</em>
                </Select.Option>
              </Select>
            </div>
          </div>

          <div className='instances'>
            {props.raid.available_instances.map((instance) => (
              <div key={instance.id}>
                <h3 onClick={() => toggleInstance(instance)}>
                  {instance.name}
                </h3>
                <div className='encounters'>
                  {instance.encounters.map((encounter) => {
                    const orderId = `${encounter.id}|${difficultiesForId[difficulty]}`
                    const enabled = enabledEncounters.includes(orderId)
                    const fixed =
                      props.raid.instance.id === instance.id &&
                      props.raid.difficulty === difficulty
                    return (
                      <div
                        key={encounter.id}
                        className={enabled ? 'active' : ''}
                      >
                        {props.guild.kind === 'classic_progression' ? (
                          <img src={encounter.zoom_url} />
                        ) : (
                          <Image name={encounter.background} extension='jpg' />
                        )}
                        <Checkbox
                          checked={enabled || fixed}
                          onChange={() =>
                            setEnabledEncounters(
                              enabledEncounters.includes(orderId)
                                ? enabledEncounters.filter(
                                    (eid) => eid !== orderId,
                                  )
                                : [...enabledEncounters, orderId],
                            )
                          }
                          disabled={fixed}
                        >
                          {encounter.name}
                        </Checkbox>
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddEncounterModal

const ModalContentStyles = css`
  .ant-select {
    margin: 0 0.5rem;
    &.difficulty {
      width: 10rem;
    }
    &.add-before {
      width: 20rem;
    }
  }

  .field-group .field label {
    font-weight: bold;
  }

  .intro {
    margin-bottom: 1rem;
  }

  .instances {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;

    h3 {
      font-size: 1.5rem;
      max-width: 20rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }

    > div {
      .encounters {
        > div {
          display: flex;
          border: 0.1rem solid #5a5a5a;
          padding: 0.3rem 0.5rem;
          margin-bottom: 0.5rem;
          align-items: center;
          border-radius: 0.5rem;

          .ant-checkbox-wrapper span:not(.ant-checkbox) {
            opacity: 0.7;
          }

          .ant-checkbox-checked::after {
            display: none;
          }

          &.active {
            color: #399652;
            border-color: #399652;

            .ant-checkbox-wrapper span {
              color: #399652;
            }

            &:not(.ant-checkbox) {
              opacity: 1;
            }
          }

          img {
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1rem;
            position: absolute;
            pointer-events: none;
          }

          label.ant-checkbox-wrapper {
            display: flex;
            flex-direction: row-reverse;

            > span:not(.ant-checkbox) {
              width: 18.5rem;
              padding-left: 2.2rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .ant-checkbox-inner {
            top: 0.4rem;
          }
        }
      }
    }
  }
`
