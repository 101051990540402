import React from 'react'
import { Link } from 'react-router-dom'
import 'react-circular-progressbar/dist/styles.css'

import RefreshStatusBlock from '../../../components/RefreshStatusBlock'
import ActivityAlert from '../../../components/ActivityAlert'

import TeamService from '../../../services/TeamService'

import { Icon, Alert } from 'antd'

class SpreadsheetStatusPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: null,
      refreshing: null,
      currentTeam: this.props.guild.selectedTeam.id,
    }
  }

  componentDidMount() {
    this.refreshStatus()
    this.setState({
      refreshing: setInterval(() => {
        if (document.hasFocus()) {
          this.refreshStatus()
        }
      }, 30000),
    })
  }

  componentWillUnmount() {
    this.setState({ refreshing: clearInterval(this.state.refreshing) })
  }

  componentDidUpdate() {
    // TODO: Refactor this, improve state management
    if (this.state.currentTeam != this.props.guild.selectedTeam.id) {
      this.setState({ currentTeam: this.props.guild.selectedTeam.id })
      this.refreshStatus()
    }
  }

  refreshStatus() {
    TeamService.fetchRefreshStatus(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
    ).then((result) => {
      this.setState({ status: result.data.status })
    })
  }

  render() {
    const types =
      this.props.guild.kind === 'live'
        ? ['collections', 'raiderio', 'wcl']
        : ['collections']
    let factor = this.state.status && this.state.status.factor
    return (
      <div className='refresh-status-page'>
        <h1 className='title-caption'>
          <div>Spreadsheet - Team {this.props.guild.selectedTeam.name}</div>
          <Icon
            type='sync'
            spin={
              this.props.guild.selectedTeam.characters.length > 0 &&
              this.props.guild.active
            }
          />
          Refresh Status
        </h1>

        {this.props.guild.active && (
          <React.Fragment>
            {this.props.guild.selectedTeam.characters.length > 0 && (
              <div className='status-blocks'>
                {types.map((type, _) => (
                  <RefreshStatusBlock
                    guild={this.props.guild}
                    user={this.props.user}
                    type={type}
                    key={type}
                    status={this.state.status && this.state.status[type]}
                  />
                ))}
              </div>
            )}
            {this.props.guild.selectedTeam.characters.length == 0 && (
              <Alert
                type='warning'
                showIcon
                message={
                  <React.Fragment>
                    This team&apos;s roster is empty. Data won&apos;t be
                    retrieved unless there is at least one character being
                    tracked. Go to the{' '}
                    <Link to={`${this.props.guild.selectedTeam.path}/roster`}>
                      Roster
                    </Link>{' '}
                    page and start tracking characters!
                  </React.Fragment>
                }
              />
            )}

            <div className='alert-container'>
              {this.state.status && (
                <Alert
                  type={factor > 2 ? 'success' : 'info'}
                  showIcon
                  icon={<Icon type='sync' />}
                  message={
                    <span>
                      Your data is refreshing at{' '}
                      {factor == 1 ? (
                        ''
                      ) : (
                        <span>
                          <strong>{factor}</strong> times{' '}
                        </span>
                      )}
                      the normal rate.&nbsp;
                      {factor < 30 && (
                        <React.Fragment>
                          You can go to the{' '}
                          <Link
                            to={`${this.props.guild.selectedTeam.path}/premium`}
                          >
                            {' '}
                            Patreon page{' '}
                          </Link>
                          {!this.props.guild.api_key && (
                            <React.Fragment>
                              or configure your own API credentials in the
                              <Link
                                to={`${this.props.guild.selectedTeam.path}/settings`}
                              >
                                {' '}
                                Settings{' '}
                              </Link>
                            </React.Fragment>
                          )}
                          to increase it{factor > 1 ? ' further.' : '.'}
                        </React.Fragment>
                      )}
                    </span>
                  }
                />
              )}
            </div>
          </React.Fragment>
        )}

        {(!this.props.guild.active ||
          (!this.props.guild.patreonLevel && this.props.guild.expiry <= 7)) && (
          <ActivityAlert
            guild={this.props.guild}
            refreshGuild={this.props.refreshGuild}
          />
        )}

        <h2>Spreadsheet data</h2>
        <p>
          Here you can view the refresh status of the different kinds of data
          shown in your spreadsheet. The <em>last refreshed</em> time visible in
          your spreadsheet will correspond to the last time the Blizzard data
          was refreshed. When for example Warcraft Logs data has refreshed, it
          will not be visible in the spreadsheet until the next time the
          Blizzard data refreshes.{' '}
          <strong>The data on this page will update automatically</strong>, you
          don&apos;t have to refresh the page.
        </p>

        <p>
          Keep in mind that pressing the refresh buttons in your spreadsheet
          does not have any effect on this page, it will only retrieve the
          latest data from the wowaudit server (your data won&apos;t refresh any
          faster).
        </p>

        <p>
          There&apos;s a limit to the amount of API requests wowaudit can make.
          Every team&apos;s data will be refreshed on a schedule, but if you
          would like the data to be refreshed more frequently you can either
          configure your own API credentials in the{' '}
          <Link to={`${this.props.guild.selectedTeam.path}/settings`}>
            {' '}
            Settings{' '}
          </Link>{' '}
          or you can pledge on{' '}
          <Link to={`${this.props.guild.selectedTeam.path}/premium`}>
            {' '}
            Patreon
          </Link>
          .
        </p>
      </div>
    )
  }
}

export default SpreadsheetStatusPage
