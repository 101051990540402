import axios from 'axios'

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

class JobService {
  async getPendingJobById(job, referenceId) {
    return await axios.get(
      `/api/jobs/${job}${referenceId ? `?reference_id=${referenceId}` : ''}`,
    )
  }
}

export default new JobService()
