import React from 'react'
import { Tooltip } from 'antd'

import JobService from './services/JobService'

export const useSetting = (props, setting) => {
  return props.guild.selectedTeam.settings.find((s) => s.field === setting)
    ?.value
}

export const difficultyOptions = {
  mythic: 'Mythic',
  heroic: 'Heroic',
  normal: 'Normal',
  raid_finder: 'LFR',
}

export const specIcon = (specIcon, specName) => {
  return (
    <Tooltip title={specName} placement='left'>
      <img
        className='spec-icon'
        alt={specName}
        src={`https://render.worldofwarcraft.com/us/icons/36/${specIcon}.jpg`}
      />
    </Tooltip>
  )
}

import { useEffect } from 'react'

// Improved version of https://usehooks.com/useOnClickOutside/
export const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside = false
    let startedWhenMounted = false

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return

      handler(event)
    }

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current
      startedInside = ref.current && ref.current.contains(event.target)
    }

    document.addEventListener('mousedown', validateEventStart)
    document.addEventListener('touchstart', validateEventStart)
    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('mousedown', validateEventStart)
      document.removeEventListener('touchstart', validateEventStart)
      document.removeEventListener('click', listener)
    }
  }, [ref, handler])
}

export const awaitPendingJob = async (jobId, callback) => {
  setTimeout(async () => {
    const pendingJob = await JobService.getPendingJobById(jobId)

    if (['queued', 'working'].includes(pendingJob.data.status)) {
      awaitPendingJob(jobId, callback)
    } else {
      if (
        ['failed', 'interrupted'].includes(pendingJob.data.status) &&
        !pendingJob.data.error
      ) {
        pendingJob.data.error = `Your request ${pendingJob.data.status} for an unknown reason. Please try again.`
      }

      callback(pendingJob.data)
    }
  }, 1000)
}
