class InstanceService {
  async fetchAll() {
    const response = await fetch(`/api/instances`, { credentials: 'include' })
    return await response.json()
  }

  async fetchByKind(kind) {
    const response = await fetch(`/api/instances?kind=${kind}`, {
      credentials: 'include',
    })
    return await response.json()
  }
}

export default new InstanceService()
